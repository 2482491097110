import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import allowlist from "../config/allowlist.json";

// eslint-disable-next-line import/no-anonymous-default-export
export default new (class Allowlist {
  merkleTree;

  getMerkleTree() {
    if (this.merkleTree === undefined) {
      const leafNodes = allowlist.map((addr) => keccak256(addr));
      this.merkleTree = new MerkleTree(leafNodes, keccak256, {
        sortPairs: true,
      });
    }

    return this.merkleTree;
  }

  getProofForAddress(address) {
    return this.getMerkleTree().getHexProof(keccak256(address));
  }

  getRawProofForAddress(address) {
    return this.getProofForAddress(address)
      .toString()
      .replaceAll("'", "")
      .replaceAll(" ", "");
  }

  contains(address) {
    return (
      this.getMerkleTree().getLeafIndex(Buffer.from(keccak256(address))) >= 0
    );
  }
})();
